import * as React from 'react';
import PropTypes from 'prop-types';
import { TransitionProvider, TransitionViews } from 'gatsby-plugin-transitions';
import './index.css';

const Layout = ({ location, children }) => {
  return (
    <div
      css={{
        margin: `0 auto`,
        maxWidth: `var(--size-content)`,
        padding: `var(--size-gutter)`,
        height: '100%',
      }}
    >
      <main css={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
        <TransitionProvider location={location} css={{ height: '100%' }}>
          <TransitionViews css={{ height: '100%' }}>
            {children}
          </TransitionViews>
        </TransitionProvider>
      </main>
    </div>
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Layout;
