exports.components = {
  "component---node-modules-gatsby-plugin-transitions-src-pages-custom-js": () => import("./../../../node_modules/gatsby-plugin-transitions/src/pages/custom.js" /* webpackChunkName: "component---node-modules-gatsby-plugin-transitions-src-pages-custom-js" */),
  "component---src-components-result-page-index-js": () => import("./../../../src/components/ResultPage/index.js" /* webpackChunkName: "component---src-components-result-page-index-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-en-index-js": () => import("./../../../src/pages/en/index.js" /* webpackChunkName: "component---src-pages-en-index-js" */),
  "component---src-pages-en-question-js": () => import("./../../../src/pages/en/question.js" /* webpackChunkName: "component---src-pages-en-question-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-ja-index-js": () => import("./../../../src/pages/ja/index.js" /* webpackChunkName: "component---src-pages-ja-index-js" */),
  "component---src-pages-ja-question-js": () => import("./../../../src/pages/ja/question.js" /* webpackChunkName: "component---src-pages-ja-question-js" */),
  "component---src-pages-ko-index-js": () => import("./../../../src/pages/ko/index.js" /* webpackChunkName: "component---src-pages-ko-index-js" */),
  "component---src-pages-ko-question-js": () => import("./../../../src/pages/ko/question.js" /* webpackChunkName: "component---src-pages-ko-question-js" */),
  "component---src-pages-question-js": () => import("./../../../src/pages/question.js" /* webpackChunkName: "component---src-pages-question-js" */),
  "component---src-pages-th-index-js": () => import("./../../../src/pages/th/index.js" /* webpackChunkName: "component---src-pages-th-index-js" */),
  "component---src-pages-th-question-js": () => import("./../../../src/pages/th/question.js" /* webpackChunkName: "component---src-pages-th-question-js" */),
  "component---src-pages-zh-index-js": () => import("./../../../src/pages/zh/index.js" /* webpackChunkName: "component---src-pages-zh-index-js" */),
  "component---src-pages-zh-question-js": () => import("./../../../src/pages/zh/question.js" /* webpackChunkName: "component---src-pages-zh-question-js" */)
}

